/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

$(async function () {

	// Responsive menu
	$('.toggler').click(function () {
		var $this = $(this),
			responsiveNav = $('.responsive-nav');

		responsiveNav.toggleClass('-open');
		$this.toggleClass('is-active');
	});

	// Match height
	var matchHeight = $('.match-height');
	if (matchHeight.length) {
		await import('jquery-match-height');

		matchHeight.matchHeight({
			property: 'height'
		});
	}

	// Slideshow header
	$('.slideshow.swiper-container').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;

		new Swiper(this, {
			keyboard: true,
			effect: 'fade',
			grabCursor: true,
			loop: true,
			slidesPerView: 1,
			autoplay: {
				delay: 3000
			},
			speed: 1500
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});

	// Jump to submitted form
	$('.umbraco-forms-form[id]:has(form)').each(function () {
		var $this = $(this),
			form = $this.find('form');

		form.attr('action', form.attr('action') + '#' + $this.attr('id'));
	});
});